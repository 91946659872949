<template>
  <div v-if="!this.$func.checkPermission('รายการแจ้งถอน')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header" class="row">
            <div class="col-12 col-sm my-auto">
              <h4 class="mt-0 mb-2">
                รายการแจ้งถอน <br class="d-block d-md-none" />
                (ยอดถอนรวม :
                {{
                  Number(totalWithdraw).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }})
              </h4>
            </div>
            <div class="col-9 col-sm-4">
              <div class="d-flex">
                <label for="remark" class="mr-2 my-auto">สถานะ</label>
                <select
                  v-model="remark"
                  class="form-control"
                  @change="changeRemark()"
                  id="remark"
                >
                  <option value="" selected>ทั้งหมด</option>
                  <option value="0">รอดำเนินการ</option>
                  <option value="4">กำลังดำเนินการ</option>
                </select>
              </div>
            </div>
            <div class="col-3 col-sm-2 ml-auto text-right">
              <el-button
                type="danger"
                size="default"
                @click="getWithdrawList()"
                :loading="loadingRefresh"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableData"
                border
                v-loading="loading"
              >
                <el-table-column :min-width="75" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.$index + 1 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="Username">
                  <template v-slot="props">
                    <span class="mr-2" v-html="props ? props.row.phone : ''">
                    </span>
                    <button
                      @click="historyMember(props.row.phone)"
                      class="btn btn-primary btn-sm"
                    >
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="ชื่อ-สกุล">
                  <template v-slot="props">
                    <span v-html="props ? props.row.fname : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Userbet">
                  <template v-slot="props">
                    <span v-html="props ? props.row.username : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เครดิต">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.credit).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    >
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เลขบัญชี">
                  <template v-slot="props">
                    <span v-html="props ? props.row.bank_number : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ธนาคาร">
                  <template v-slot="props">
                    <span v-html="props ? props.row.bank_name : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="สถานะ">
                  <template v-slot="props">
                    <span
                      class="text-danger"
                      v-html="props ? props.row.remark : ''"
                    >
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="หมายเหตุ">
                  <template v-slot="props">
                    <div v-if="props">
                      <fg-input>
                        <textarea
                          class="form-control"
                          placeholder="กรอกหมายเหตุ"
                          rows="2"
                          :id="`cancel_` + props.row.id"
                          @click="detail = ''"
                        ></textarea>
                      </fg-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="200"
                  fixed="right"
                  label="ทำรายการ"
                >
                  <template v-slot="props">
                    <div v-if="props">
                      <button
                        v-if="props.row.type === 0"
                        @click="transfers_auto(props.row.id, props.row.deposit)"
                        class="btn btn-primary btn-sm mr-1 w-80px"
                      >
                        ถอนระบบ
                      </button>
                      <button
                        @click="transfers(props.row.id)"
                        class="btn btn-success btn-sm mr-1 w-80px"
                      >
                        <span v-if="props.row.type === 4">สำเร็จ</span>
                        <span v-else>ถอนแอพ</span>
                      </button>
                      <button
                        @click="
                          cancel_transfers(props.row.id, props.row.phone, 3)
                        "
                        class="btn btn-warning btn-sm mr-1 w-80px"
                      >
                        คืนเครดิต
                      </button>
                      <button
                        v-if="props.row.type === 0"
                        @click="
                          cancel_transfers(props.row.id, props.row.phone, 2)
                        "
                        class="btn btn-danger btn-sm mr-1 w-80px"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog
      :visible.sync="modals.historyMember"
      width="90%"
      :lock-scroll="false"
    >
      <span slot="title">
        <i class="fa fa-mobile" aria-hidden="true"></i>
        เบอร์ {{ historyMemberPhone }}
      </span>
      <div>
        <TableHistoryMember :phone="historyMemberPhone" />
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('historyMember')">
          ปิด
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalQrcode" width="20%">
      <span slot="title">
        <i class="fa fa-qrcode" aria-hidden="true"></i>
        Qrcode
      </span>
      <div>
        <qrcode :text="qrcodevalue" class="qrcode m-auto"></qrcode>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('modalQrcode')">
          ปิด
        </button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="modals.modalTransfersAuto"
      width="30%"
      v-loading="loadingSubmit"
    >
      <span slot="title"> <i class="fas fa-exchange-alt"></i> ถอนระบบ </span>
      <div>
        <label for="">เลือกธนาคารถอน:</label>
        <el-select
          class="select-default mb-3 w-100"
          v-model="acc"
          placeholder="เลือกธนาคาร"
        >
          <el-option
            class="select-default"
            v-for="item in selects.bankWithdraw"
            :key="item.id"
            :label="`${item.fname} ${item.lname} ${item.number}`"
            :value="item.number"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          @click="submitTransfersAuto()"
          type="button"
          class="btn btn-success mr-2"
        >
          ยืนยัน
        </button>
        <button
          class="btn btn-danger"
          @click="closeModal('modalTransfersAuto')"
        >
          ปิด
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Dialog,
} from "element-ui";
import {
  StatsCard,
  Pagination as LPagination,
  FormGroupInput as FgInput,
} from "src/components/index";
import axios from "axios";
import moment from "moment";
import qrcode from "vue-qrcode-component";
import Swal from "sweetalert2";
import { select } from "d3";
import TableHistoryMember from "../components/TableHistoryMember.vue";

export default {
  components: {
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    LPagination,
    qrcode,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    TableHistoryMember,
  },
  data() {
    return {
      loading: true,
      loadingMember: true,
      loadingSubmit: false,
      deposit: 0,
      income: 0,
      bonus: 0,
      withdraw: 0,
      historyMemberPhone: "",
      remark: 0,
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      transfers_auto_id: "",
      acc: "",
      modals: {
        historyMember: false,
        modalQrcode: false,
        WithdrawCredit: false,
        modalTransfersAuto: false,
        PullCredit: false,
        centered: false,
        custom: false,
        confirm: false,
      },
      selects: {
        simple: "",
        countries: [
          { value: "Bahasa Indonesia", label: "Bahasa Indonesia" },
          { value: "Bahasa Melayu", label: "Bahasa Melayu" },
          { value: "Català", label: "Català" },
        ],
        bankWithdraw: [],
        remark: [
          { value: 0, label: "รอดำเนินการ" },
          { value: 4, label: "กำลังดำเนินการ" },
        ],
      },

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },
      paginationHistoryMember: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },

      searchQuery: "",

      tableData: [],
      tableDataHistoryMember: [],
      qrcodevalue: "",
      countWithdraw: 0,

      refreshWithdraw: "",

      totalWithdraw: 0,

      loadingRefresh: false,
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      if (this.modals["historyMember"] === true) return;
      this.getWithdrawList();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },

    toMember() {
      let highBound = this.fromMember + this.paginationHistoryMember.perPage;
      if (this.totalMember < highBound) {
        highBound = this.totalMember;
      }
      return highBound;
    },
    fromMember() {
      if (this.modals["historyMember"] === false) return;
      this.getDashboardAllByPhone();
      return (
        this.paginationHistoryMember.perPage *
        (this.paginationHistoryMember.currentPage - 1)
      );
    },
    totalMember() {
      return this.paginationHistoryMember.total;
    },
  },

  mounted() {
    this.refreshWithdraw = setInterval(() => {
      this.getWithdrawList();
    }, 10000);
  },
  methods: {
    changeRemark() {
      // this.getWithdrawList();
    },

    playAudio() {
      if (!this.audioBuffer || !this.audioContext) {
        // console.error("ยังไม่ได้โหลดข้อมูลเสียง.");
        return;
      }

      // ตรวจสอบว่าต้องการการสร้าง AudioContext และเรียก resume() หากจำเป็น
      if (!this.audioContext.state || this.audioContext.state === "suspended") {
        this.audioContext.resume().then(() => {
          this.internalPlayAudio();
        });
      } else {
        this.internalPlayAudio();
      }
    },
    internalPlayAudio() {
      if (this.audioSource) {
        this.audioSource.stop();
      }

      const audioSource = this.audioContext.createBufferSource();
      audioSource.buffer = this.audioBuffer;
      audioSource.connect(this.audioContext.destination);
      audioSource.start();

      this.audioSource = audioSource;
    },
    async getWithdrawList() {
      this.loadingRefresh = true;
      this.totalWithdraw = 0;
      // this.loading = true;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/list_withdraw?remark=` + this.remark,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };

      await axios
        .request(config)
        .then((response) => {
          this.tableData = [];
          this.loadingRefresh = false;
          this.loading = false;
          let data = response.data;
          if (data.res_code === "00") {
            this.tableData = data.res_result;

            this.pagination.total = this.tableData.length;

            data.res_result.forEach((el) => {
              this.totalWithdraw = this.totalWithdraw + parseInt(el.deposit);
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    qrshow(value) {
      this.qrcodevalue = value;
      this.modals["modalQrcode"] = true;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    async historyMember(phone) {
      this.modals["historyMember"] = true;
      this.historyMemberPhone = phone;

      return;
      this.loading = true;

      let data = JSON.stringify({
        phone: phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/historymember`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          this.loading = false;

          if (data.res_code === "00") {
            data = data.res_result[0];

            if (!data.deposit && !data.withdraw && !data.bonus) {
              return Swal.fire("แจ้งเตือน", "ไม่พบรายการฝาก", "error");
            }

            this.bonus = data.bonus ? data.bonus : 0;
            this.deposit = data.deposit;
            this.withdraw = data.withdraw;
            this.income = data.deposit - data.withdraw - data.bonus;

            // this.getDashboardAllByPhone();
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async getDashboardAllByPhone() {
      this.loadingMember = true;
      let offset =
        this.paginationHistoryMember.perPage *
        (this.paginationHistoryMember.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/transactions?search=${this.historyMemberPhone}&sort&order=desc&offset=${offset}&limit=${this.paginationHistoryMember.perPage}&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };

      clearTimeout(this.timeDataMember);
      this.timeDataMember = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            let data = res.data;
            if (data.res_code === "00") {
              this.tableDataHistoryMember = [];
              this.tableDataHistoryMember = data.res_result.rows;
              this.paginationHistoryMember.total = data.res_result.total;
            }
            this.loadingMember = false;
          })
          .catch((error) => {
            this.loadingMember = false;
          });
      }, 500);
    },
    async transfers_auto(id, amount) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getbank?search=&sort&order=desc&offset=0&limit=10&action&start_date=&end_date=&withdraw=1`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      Swal.showLoading();

      await axios
        .request(config)
        .then((res) => {
          Swal.close();
          let data = res.data;
          this.selects.bankWithdraw = [];
          if (data.res_code === "00") {
            this.selects.bankWithdraw = data.res_result.rows;
            this.transfers_auto_id = id;
            this.modals["modalTransfersAuto"] = true;
          }
        })
        .catch((error) => {});
    },
    submitTransfersAuto() {
      this.loadingSubmit = true;
      if (!this.acc || !this.transfers_auto_id) {
        Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
        this.loadingSubmit = false;
        return;
      }
      Swal.fire({
        title: "ยืนยันถอนระบบ?",
        text: "คุณจะไม่สามารถยกเลิกสิ่งนี้ได้ !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = JSON.stringify({
            id: this.transfers_auto_id,
            acc: this.acc,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/update_withdrawauto`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          try {
            const res = await axios.request(config);
            this.loadingSubmit = false;
            let data = res.data;
            if (data.res_code === "00") {
              Swal.fire({
                title: "แจ้งเตือน",
                text: data.res_text,
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
              this.tableData = [];
              this.getWithdrawList();
              this.modals["modalTransfersAuto"] = false;
            } else {
              Swal.fire("แจ้งเตือน", data.res_text, "error");
            }
          } catch (error) {
            this.loadingSubmit = false;
            Swal.fire("แจ้งเตือน", error.response.data, "error");
          }
        } else {
          this.loadingSubmit = false;
        }
      });
    },
    transfers(id) {
      Swal.fire({
        title: "ยืนยันถอนแอพ?",
        text: "คุณจะไม่สามารถยกเลิกสิ่งนี้ได้ !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadingSubmit = true;
          let data = JSON.stringify({
            id: id,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/update_withdraw`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((response) => {
              this.loadingSubmit = false;
              let data = response.data;
              if (data.res_code === "00") {
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
              this.tableData = [];
              this.getWithdrawList();
            })
            .catch((error) => {
              this.loadingSubmit = false;
            });
        }
        this.loadingSubmit = false;
      });
    },
    cancel_transfers(id, phone, status) {
      let description = document.getElementById("cancel_" + id);
      if (!description.value) {
        Swal.fire("แจ้งเตือน", "กรุณากรอกหมายเหตุ", "warning");
        return description.focus();
      }

      Swal.fire({
        title: "ยืนยันคืนเครดิต?",
        text: "คุณจะไม่สามารถยกเลิกสิ่งนี้ได้ !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadingSubmit = true;
          let data = JSON.stringify({
            phone: phone,
            id: id,
            staus: status,
            description: description.value,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/cancel`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((response) => {
              this.loadingSubmit = false;
              let data = response.data;
              if (data.res_code === "00") {
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
              this.tableData = [];
              this.getWithdrawList();
            })
            .catch((error) => {
              this.loadingSubmit = false;
            });
        }
      });
    },
    closeModal(name) {
      this.historyMemberPhone = "";
      this.modals[name] = false;
    },
    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["modalTransfersAuto"] = false;
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshWithdraw);
  },
  watch: {
    searchQuery(newSearch, oldSearch) {
      this.searchQuery = newSearch;
    },
  },
};
</script>
<style scoped>
.w-80px {
  width: 80px;
}
</style>
